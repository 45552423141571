<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <div class="generic-wizard-heading">Select Your Course Group</div>
      </div>
    </div>
    <skeleton-loader id="groupLoader" :count="3" :showEmptyTemplate="true" :defaultLoadingProp="true">
      <template v-slot:default="{ isLoading }">
        <div class="generic-layout-cntr" v-if="selectedClassGroup && courseGroups">
          <div
            @click="
              updateSelectedCourseGroup(item);
              UpdateNextWizard();
            "
            class="board-box"
            v-for="(item, index) in courseGroupsList"
            :key="index"
          >
            <div class="course-icon">
              <img :src="item.Icon" />
            </div>

            <div class="board-box-head">
              {{ item.Name }}
            </div>
          </div>
        </div>
        <div v-else-if="isLoading" class="text-center my-5">No course group found.</div>
      </template>
    </skeleton-loader>
    <!-- <skeleton-loader id="boardLoader" :count="3"></skeleton-loader> -->
    <div class="d-flex justify-content-center">
      <button @click="updateWizard('wizard-step2')" class="btn secondary-btn" :disabled="isNextButtonDisabled">Go back</button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { courseGroup as courseGroupStoreKeys, course as courseStoreKeys } from "../../../store/keys";
import { appConstants } from "../../../common/constants";
import breadcrumb from "../breadcrumb.vue";
export default {
  // Client-side only
  components: {
    breadcrumb,
  },

  mounted() {
    this.getCourseGroups();
    //this.updateSelectedCourseGroup(this.courseGroups[0]);
  },
  data() {
    return {
      isNextButtonDisabled: true,
      selectedClassGroup: null,
      courseGroupsList: [],
    };
  },
  destroyed() {
    //this.resetCourseGroups();
  },
  // Server-side only
  serverPrefetch() {},
  computed: {
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(courseGroupStoreKeys.namespace, courseGroupStoreKeys.getters),
  },
  methods: {
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(courseGroupStoreKeys.namespace, courseGroupStoreKeys.actions),
    updateWizard(wizard) {
      this.$emit("onUpdateWizard", wizard);
    },
    courseSubscriptionType() {
      let subscriptionType = this.$route.name === "FreeTrialClass" ? appConstants.enums.subscriptionType.Free : appConstants.enums.subscriptionType.All;
      return subscriptionType;
    },
    getCourseGroups() {
      this.get_CourseGroups({
        data: {
          AcademicProgramId: this.selectedProgram.id,
          BoardId: this.selectedBoard.id,
          SubscriptionType: this.courseSubscriptionType(),
        },
        config: {
          loader: {
            show: true,
            id:"groupLoader",
          },
        },
      })
        .then((response) => {
          this.courseGroupsList = response.CourseGroups;

          this.updateSelectedCourseGroup(
            this.selectedCourseInfo.courseGroup.id === 0
              ? response.CourseGroups[0]
              : response.CourseGroups.find((courseGroup) => {
                  return courseGroup.CourseGroupId === this.selectedCourseInfo.courseGroup.id;
                })
          );

          this.update_SelectedCourseGroup({
            data: {
              id: 0,
              name: "",
            },
          });
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    // resetCourseGroups() {
    //   // this.reset_CourseGroups().then(response => {});
    // },
    updateSelectedCourseGroup(courseGroup) {
      this.selectedClassGroup = courseGroup;
      this.isNextButtonDisabled = false;
    },
    UpdateNextWizard() {
      this.update_SelectedCourseGroup({
        data: {
          id: this.selectedClassGroup.CourseGroupId,
          name: this.selectedClassGroup.Name,
        },
        config: {
          loader: {
            show: true,
          },
        },
      })
        .then((response) => {
          this.$emit("onUpdateWizard", "wizard-step4");
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
  },
};
</script>
